<template>
    <div class="boby-by">
      <div id="content" style="max-width:100%;margin: 0px;padding: 0 3%;">
        <div class="pagegame" style="padding-top:10px;">
          <div class="right">
            <swiper :options="swiperOption">
              <swiper-slide>
                <a href="#"> <img src="../../../public/static/cloud/img/1.jpg" /> </a>
              </swiper-slide>
              <swiper-slide>
                <a href="#"> <img src="../../../public/static/cloud/img/2.jpg" /> </a>
              </swiper-slide>
              <swiper-slide>
                <a href="#"> <img src="../../../public/static/cloud/img/3.jpg" /> </a>
              </swiper-slide>
              <div class="swiper-button-prev"
                   slot="button-prev"></div>
              <div class="swiper-button-next"
                   slot="button-next"></div>
              <div class="swiper-pagination"
                   slot="pagination"></div>
            </swiper>
            <!-- 广告区 -->
            <section class="gamelist gamelist2">
              <el-row :gutter="20"
                      v-loading="Loading">
                <el-col :span="24"
                        style="padding-bottom:10px;"
                        v-for="(item, index) in listData[currentIndex].data"
                        :key="index">
                  <el-card shadow="hover">
                    <el-row :gutter="20">
                      <el-col :span="20">
                        <div>
                          <img v-if="item.type == 1"
                              src="../../../public/static/cloud/img/qt.png" style="position: relative;left: -20px;top: -20px;"/>
                          <img v-else
                              src="../../../public/static/cloud/img/xm.png" style="position: relative;left: -20px;top: -20px;"/>
                              <span style="width: 116%;float: left;white-space: nowrap; overflow: hidden;text-overflow: clip;">{{  item.name  }}</span>
                        </div>
                      </el-col>
                      <el-col :span="4">
                        <div style="text-align: right;margin-top:13px;">
                          <el-button size="mini"
                                     round
                                     @click="url_address(item.cloud_url)">试玩
                          </el-button>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>
              </el-row>
            </section>
            <!-- 广告区end -->
          </div>
        </div>
      </div>
  
    </div>
  </template>
  
  <script>
  import Vue from 'vue'
  import "swiper/dist/css/swiper.css";
  import { swiper, swiperSlide } from "vue-awesome-swiper";
  export default {
    name: "home",
    components: {
      swiper,
      swiperSlide,
    },
    data () {
      return {
        Loading: false,
        LoginDialogVisible: false,
        currentIndex: 0,
        listData: [],
        swiperOption: {
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          autoplay: {
            delay: 1500,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        },
      };
    },
    created () {
      document.title = "云游戏"
      this.isMobile();
      this.beforeCreate()
      this.getCloudData()
      this.GetTimer() //定时更新列表
    },
    methods: {
        isMobile() {
            var flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            if (flag) {
                this.$router.replace('/mobile');
            } else {
                this.$router.replace('/index');
            }
            },
      //读取云游戏
      getCloudData () {
        this.Loading = true
        this.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.weburl + '/cloud/getCloudData',
          // 传递参数
          data: {},
          responseType: 'json'
        }).then(response => {
          this.Loading = false
          if (response.data.code == 200) {
            this.listData = response.data.info
          } else {
            this.$message({
              message: response.data.msg,
              type: 'warning'
            });
          }
        }).catch(error => {
          this.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
      tabChange (index) {
        this.currentIndex = index;
      },
      beforeCreate () {
        document.querySelector('body').setAttribute('style', 'background:#1e2831')
        document.querySelector('html').setAttribute('style', 'background:#1e2831')
      },
      url_address (url) {
        window.open(url, '_blank')
      },
      GetTimer () {
        //   setInterval(() => {
        //     this.getCloudData()
        //   }, 20000)
      },
      //关闭
      LoginClose () {
        this.$message({
           message: "登录功能维护中",
           type: "warning",
         });
       // this.LoginDialogVisible = false
      },
  
    },
  };
  </script>
  
  <style lang="less">
    #root, body, html{
    min-width:0px;
  }
</style>
  
  <style scoped lang="less">

  #content {
    max-width: 1920px;
    padding: 0 8%;
    margin: 0 auto;
    position: relative;
  
    /deep/ .el-card__body,
    .el-main {
      background: -webkit-linear-gradient(left, #b3a876, #1e2831);
    }
  
    /deep/ .el-card {
      border: 0px solid #ebeef5 !important;
    }
  }
  
  .pagegame {
    display: flex;
    padding-top: 40px;
  
    > aside {
      min-width: 200px;
    }
  }
  
  .f13 {
    font-size: 13px !important;
  }
  
  .topic {
    margin-right: 38px;
    background: #28333d;
    border-radius: 4px;
    overflow: hidden;
    position: sticky;
    top: 120px;
  
    > div {
      display: block;
      height: 70px;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      padding-left: 24px;
      padding-right: 24px;
      color: #fff;
      opacity: 0.5;
      cursor: pointer;
    }
  
    > div.on {
      opacity: 1;
      background: #304051;
      font-weight: 700;
    }
  
    > div:hover {
      opacity: 1;
      background: #304051;
    }
  }
  
  .right {
    flex: 1;
    width: 100px;
  
    .gamelist {
      width: 100%;
  
      .gamelist-wrap {
        margin: -8px;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
  
        .gameitem {
          width: 20%;
          box-sizing: border-box;
          padding: 8px;
          position: relative;
  
          .gameitem-tag {
            width: 52px;
            height: 52px;
            background: url(../../assets/cloud/tag_hot.png) 0 0 no-repeat;
            background-size: contain;
            position: absolute;
            top: 2px;
            right: 2px;
            z-index: 1;
          }
  
          .gameitem-tag.gameitem-hot {
            background-image: url(../../assets/cloud/tag_hot.png);
          }
  
          .gameitem-tag.gameitem-free {
            background-image: url(../../assets/cloud/tag_free.png);
          }
  
          .gameitem-cont {
            background: #28333d;
            border-radius: 4px;
            height: 100%;
            padding: 15%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
  
            .gameitem-img {
              height: 0;
              padding-bottom: 100%;
              position: relative;
              box-sizing: border-box;
              width: 100%;
              border-radius: 24%;
  
              img {
                width: 100%;
                border-radius: 24%;
              }
            }
  
            .gameitem-intr {
              text-align: center;
              margin: 0 -10% auto;
  
              h2 {
                font-weight: 400;
                padding: 15% 0;
                font-size: 13px;
                margin: 0;
              }
            }
  
            .g-Btn-green2 {
              padding-left: 0;
              padding-right: 0;
              width: 80px;
              max-width: 100%;
              height: 30px;
              line-height: 28px;
              margin: 0 auto;
              font-size: 14px;
            }
  
            .g-Btn-green2 {
              border: 1px solid #03c47e;
              background: #03c47e;
              color: #fff;
              font-weight: 700;
            }
  
            .g-Btn-green2:hover {
              transition: background-image 3s ease-in-out 1s;
              background-image: radial-gradient(
                ellipse farthest-corner at bottom,
                #aafced 0,
                #03c47e 65%
              );
            }
  
            .g-Btn {
              vertical-align: middle;
              text-align: center;
              height: 36px;
              line-height: 34px;
              display: inline-block;
              border-radius: 20px;
              box-sizing: border-box;
              white-space: nowrap;
              user-select: none;
              cursor: pointer;
            }
          }
        }
  
        @media only screen and (min-width: 1919px) {
          .gameitem {
            width: 14.28571429%;
          }
        }
  
        @media only screen and (max-width: 1024px) {
          .gameitem {
            width: 25%;
          }
        }
  
        @media only screen and (max-width: 768px) {
          .gameitem {
            width: 33.33333333%;
          }
        }
      }
    }
  
    .swiper-container {
      margin-bottom: 16px;
    }
  
    .swiper-slide {
      a {
        display: block;
        width: 100%;
  
        img {
          display: block;
          width: 100%;
        }
      }
    }
  
    .swiper-pagination {
      /deep/ .swiper-pagination-bullet-active {
        background: #fff;
      }
    }
  
    .button-prev,
    .button-next {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 48px;
      height: 48px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  
    .swiper-button-prev {
      background: url("../../assets/cloud/left.png") no-repeat;
      background-size: cover;
      width: 44px;
      left: 32px;
    }
  
    .swiper-button-next {
      background: url("../../assets/cloud/right.png") no-repeat;
      background-size: cover;
      width: 44px;
      right: 32px;
    }
  }
  </style>
  